/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};

document.addEventListener("DOMContentLoaded", function () {
	// fixed header
	var headerFullHeight = $(".header-content-section").height() + $(".header").height();
	if ($(".header-content-section").length < 1) {
		headerFullHeight = 200;
	}

	$(window).on('scroll', function() {
		if ($(window).width() > 767) {
			if ($(window).scrollTop() > headerFullHeight) {
				$(".topmain-full").addClass("header-fixed");
			} else {
				if ($(".topmain-full").addClass("header-fixed")) {
					$(".topmain-full").removeClass("header-fixed");
				}
			}
		}
	});
});

$(function() {
  $(document).ready(function() {
    var autoHeight = $(window).width() > 767;

    // Testimonials slider
    $('.testimonials-slider').owlCarousel({
      items: 1,
			loop: true,
      autoPlay: true,
      autoplayTimeout: 10000,
      dots: true,
      nav : true,
      autoHeight: autoHeight
    });
  });

  function unBindSignInClick() {
	   $('#manage_btn').off('click');
	};
	unBindSignInClick();

//	TABS
	(function ($) {
		$('.tabs').addClass('active').find('> li:eq(0)').addClass('current');

		$('.tabs li a').click(function (g) {
			var tab = $(this).closest('.tabs-section'),
					index = $(this).closest('li').index();

			tab.find('.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');

			tab.find('.tabs-content').find('div.tabs-item').not('div.tabs-item:eq(' + index + ')').slideUp();
			tab.find('.tabs-content').find('div.tabs-item:eq(' + index + ')').slideDown();

			g.preventDefault();
		});
	})(jQuery);

  $.getScript('/assets/js/calc.js', function () {

    //CALC MINI
    if ($('[data-calc-mini]').length > 0) {
      new Calc({
        ID: '23',
        calculatorType: 'DefaultCalculator',
        format: 'html',
        options: {
          deafault: {
            deadlinesDefault: [66539],
            academicLevelDefault: 2,
            paperTypesDefault: 1,
            pagesDefault: 2
          }
        }
      });
    }
  });
});

// Show/hide Header Services Submenu
document.addEventListener("DOMContentLoaded", function () {
	var toggleLink = document.querySelector(".dropdown-link");
	var subMenu = document.querySelector(".submenu");
	var toggleLinkArrow = document.querySelector((".dropdown-link"), ":after");

	toggleLink.addEventListener("click", function () {
		subMenu.classList.toggle("visible-menu");
		toggleLinkArrow.classList.toggle("arr-down");
	})
});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '23',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();
